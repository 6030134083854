import Grid from '@material-ui/core/Grid'
import { Link } from 'gatsby'
import MaterialLink from '@material-ui/core/Link'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  ...theme.mixins,
  contentColumn: {
    [theme.breakpoints.down('md')]: {
      minHeight: '80vh',
      paddingBottom: '10vh',
      padding: '10vh 5vw !important'
    },
    [theme.breakpoints.up('md')]: {
      padding: '10vh 5vw 0 !important'
    }
  },
  fullHeightColumn: {
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh + 20px)',
      height: '100%'
    }
  }
})

const BlogLayout = ({ classes, children }) => (
  <div className={classes.secondaryBackground}>
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='flex-start'
      spacing={0}
    >
      <Grid item xs={10} lg={6} md={8} className={`${classes.fullHeightColumn} ${classes.clearBackground} ${classes.contentColumn}`}>
        <Typography variant='h4' color='textSecondary' gutterBottom>
          <MaterialLink component={Link} color='textSecondary' to='/'>Websitedownloader.io</MaterialLink>
        </Typography>
        {children}
      </Grid>
    </Grid>
  </div>
)

export default withStyles(styles)(BlogLayout)
